<template>
  <div class="wysiwyg">
    <p class="">
      點選箭頭，下有展場內部與展場外部選項，可選擇展場入口，直接進入展覽內部觀看，或是選擇從展場外部進入，可看見主視覺墻等設計。
    </p>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.link {
  @apply text-sm text-gray-400;
}
</style>
